import React, { useState, useEffect } from "react";
import "./App.css";

import ReactGA from "react-ga";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Navigation from "./components/Navigation";
import SplashPage from "./pages/SplashPage";
import DealersPage from "./pages/DealersPage";
import CustomersPage from "./pages/CustomersPage";
import NewsPage from "./pages/NewsPage";
import DealersByState from "./pages/DealersByState";
import Footer from "./components/Footer";

import ScrollToTop from "./components/ScrollToTop";

// function initializeAnalytics() {
//   ReactGA.initialize("UA-156956140-1");
//   ReactGA.pageview("/SplashPage");
// }

function App() {
  const [page, setPage] = useState("");

  const updateNav = otherPage => {
    setPage(otherPage);
  };

  useEffect(() => {
    ReactGA.initialize("UA-156956140-1");
    //to report pageview
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, []);


  return (
    <Router>
      <ScrollToTop />
      <Navigation currentPage={page} updateNavBar={updateNav} />
      <div className="app">
        <Switch>
          <Route path="/" exact>
            <SplashPage updateNavBar={updateNav} />
          </Route>
          <Route path="/customers">
            <CustomersPage />
          </Route>
          <Route path="/dealers" exact>
            <DealersPage />
          </Route>
          <Route path="/news">
            <NewsPage />
          </Route>
          <Route path="/dealers/:stateID/:index" component={DealersByState} />
        </Switch>
      </div>
      <Footer updateNavBar={updateNav} />
    </Router>
  );
}

export default App;
