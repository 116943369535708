import React from "react";
import ContactForm from "../components/ContactForm";
import trbg from "../images/TRbg.jpeg";
import DealerMap from "../components/DealerMap";

import { withRouter } from "react-router-dom";

class DealersPage extends React.Component {
  constructor(props) {
    super(props);


    console.log(props);


    this.state = {
      states: [
        { name: "Alabama", index: 0 },
        { name: "Arizona", index: 6 },
        { name: "California", index: 7 },
        { name: "Florida", index: 1 },
        { name: "Georgia", index: 4 },
        { name: "North Carolina", index: 3 },
        { name: "Nevada", index: 8 },
        { name: "South Carolina", index: 2 },
        { name: "Texas", index: 5 }
      ]
    };
    this.form = React.createRef();
    this.map = React.createRef();
  }

  componentDidMount () {
    if (this.props.location.state) {
      if(this.props.location.state.scrollRef === "map") {
        window.scrollTo({
          top: this.map.current.offsetTop - 111,
        });
      } else if (this.props.location.state.scrollRef === "form") {
        window.scrollTo({
          top: this.form.current.offsetTop - 111,
          behavior: "smooth"
        });
      }
    } 
  }

  visitState = (stateCode, index) => {
    console.log(stateCode);
    this.props.history.push(`/dealers/${stateCode}/${index}`);
  };

  render() {
    var headerBGStyle = {
      backgroundImage: "url(" + trbg + ")",
      backgroundSize: "cover",
      backgroundPosition: "center"
    };

    return (
      <div className="dealers-page">
        <div className="dealer-hero" style={headerBGStyle}>
          <div className="dealer-hero-wrap">
            <div className="dealer-hero-text">
              <h3 className="dealer-hero-header xxl">
                BECOME A THUNDERROAD DEALER TODAY
              </h3>
            </div>
            <div className="btn-wrap">
              
                <a
                  className="dealer-login-button-text-light lg"
                  target="_blank"
                  without="true"
                  rel="noopener noreferrer"
                  href="https://decisionlender.solutions/tci/#/auth/dealerLogin/default/dealer/default"
                >
                  DEALER LOGIN
              </a>
              

            </div>
          </div>
        </div>

        <div className="section-wrap">

          <h3 className="dealer-head gy head-txt">
            JOIN THE THUNDERROAD FAMILY
            </h3>


          <div className="section-wrap map" ref={this.map}>
            <h3 className="program-head b white subhead"> CURRENT DEALERS </h3>
            <div className="map-box">
              <DealerMap />
              <div className="dealer-states-list">
                <h3 className="program-head b white subhead">
                  CHECK OUT THESE STATES
                </h3>
                {this.state.states.map((state, index) => {
                  return (
                    <div className="rm-wrap" key={index}>
                      <div
                        onClick={() => this.visitState(state.name, state.index)}
                        className="rm-btn dealers sublg"
                      >
                        {state.name}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="map-legend">
                <h3 className="legend-header">Map Legend</h3>
                <div className="legend">
                  <ul className="legend-key">
                    <li id="legend-bullet" className="it-1">
                      Click state to view current partners
                    </li>
                    <li id="legend-bullet" className="it-2">
                      Now accepting partners
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <p className="section-text body">
            Located in northwest Nevada, ThunderRoad Financial is the third
            powersports finance venture of our CEO and President, Donal Hummer
            Jr. With over 75 years of experience in the powersports industry,
            ThunderRoad's goal is to create a one-stop-shop to fulfill all of a
            dealer's needs. We finance for all makes and models, both new and
            used, and most importantly, across all credit tiers. ThunderRoad
            strives to find the best fit for every customer. We have over 450
            dealers in 28 states; join the ThunderRoad family today.
          </p>
        </div>

        <div className="program-section">
          <h3 className="program-head b subhead">
            {" "}
            THUNDERROAD FINANCIAL'S PROGRAM OFFERS:
          </h3>
          <div className="list-text">
            <ul className="program-list">
              <li className="program-list-item body">
                New and used powersports financing
              </li>
              <li className="program-list-item body">
                An intuitive credit application and user-friendly funding
                process that streamlines your deals
              </li>
              <li className="program-list-item body">
                Competitive and flexible rates, terms, and conditions
              </li>
              <li className="program-list-item body">24-72 hour funding</li>
              <li className="program-list-item body">
                An accomplished team of relationship managers that focus on
                creating the best powersports financing experience
              </li>
            </ul>
          </div>
        </div>

        <div className="contact-info" ref={this.form}>
          <ContactForm ref={this.contactUs} />
          <div className="contact-sugg">
            <div className="section-wrap">
              <h3 className="program-head gy s xlg">OR CONTACT US AT</h3>
            </div>
          </div>
          <div>
            <div className="contact-location">
              <h4 className="contact-note-hide subhead">CONTACT US AT:</h4>
              <p className="contact-text body"> ThunderRoad Financial, LLC </p>
              <p className="contact-text body">
                {" "}
                P.O. Box 19849, Reno, Nevada 89521{" "}
              </p>
              <p className="contact-text email body">
                {" "}
                Email: info@thunderroadfinancial.com{" "}
              </p>
              <p className="contact-text phone body">Phone: (775) 332-1965 </p>
            </div>
            <div className="contact-location">
              <p className="contact-text body">
                Now accepting dealers in{" "}
                <b className="states">
                  Arizona, Alabama, California, Georgia, Florida, Nevada, North
                  Carolina, South Carolina, and Texas
                </b>
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="section-wrap bottom">
            <h3 className="program-head gy head-txt"> DEALER TESTIMONIALS</h3>
            <p className="testimonial-text body">
              "ThunderRoad is a very important part of our business. They have
              opened a whole new spectrum to making dealerships money and making
              very effective approvals. I personally love ThunderRoad because of
              their amazing staff. I feel like I am working with family and I
              also feel like I've been working with them for many, many years.
              TRF is always willing to find a way to make a deal happen because
              they trust us and believe in the customers. When I call the
              office, they all know who I am and this means the world to me. I
              have taken most of my business away from other lenders beacause of
              the staff. A lender and dealer need to be a great reflection of
              each other in order to work well together and make smart business
              decisions together. FBC and TR are great reflections of each
              other. You guys set the industry standard for how a credit
              department should function and your customer service is pure
              perfection." <br />
              <br />
              <i>- Samer Fidy, Fun Bike Center </i>
            </p>
            <p className="testimonial-text body">
              <br />
              "I have been SO happy with my experience with ThunderRoad
              Financial. They actually know how to work deals instead of hiding
              behind a computer scoring system! It is so refreshing to be able
              to call up Thunder Roads and discuss ways to make deals happen as
              opposed to why they shouldn't happen. Thank you ThunderRoad!! We
              appreciate you all!" <br /> <br />
              <i> - Director of Finance, Freedom Powersports</i>
            </p>
            <p className="testimonial-text body">
              <br />
              "ThunderRoad is taking the motorcycle industry by storm! In the
              decade and a half that I have been getting the rubber down the
              road... It feels great to have such an empowering group that gets
              it." <br />
              <br />{" "}
              <i>- JR Lebaron AKA "Ginger" Huntington Harley Davidson </i>
            </p>
            <p className="testimonial-text body">
              <br />
              "ThunderRoad Financial has been a great partner for us. They have
              been able to provide us with additional financial solutions for
              our customers that has allowed us to retain more business and
              increase our profit! The callbacks are simple and come back to you
              in a timely fashion. Couldn’t be happier with them as a partner."
              <br /> <br />
              <i>- Mike Bruno's Northshore Harley Davidson</i>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DealersPage);
