import React from "react";
import ReactGA from "react-ga";
import headerIMG from "../images/header.jpg";
import dataGraphic from "../images/dataGraphic.jpg";
import { Link } from "react-router-dom";
import cust from "../images/cust_service.svg";
import leader from "../images/leader.svg";
import partner from "../images/partner.svg";

export default class SplashPage extends React.Component {
  render() {
    var headerBGStyle = {
      backgroundImage: "url(" + headerIMG + ")",
      backgroundSize: "cover",
      backgroundPosition: " center",
    };

    var dataGraphicBGStyle = {
      backgroundImage: "url(" + dataGraphic + ")",
      backgroundSize: "cover",
      backgroundPosition: "center",
    };

    console.log(this.props);

    return (
      <div className="splash-main">
        <div className="splash-hero" style={headerBGStyle}>
          <div className="hero-text">
            <h3 className="hero-header xlg">TAKING THE ROAD BY STORM</h3>
            <p className="hero-subtext subxl">
              and providing a personal lending solution to powersports dealers
            </p>
          </div>
          <div className="btn-wrap">
            <a
              className="dealer-login-button-text-light lg"
              target="_blank"
              href="https://decisionlender.solutions/tci/#/auth/dealerLogin/default/dealer/default"
            >
              DEALER LOGIN
            </a>

            <Link
              className="dealer-login-button-text-light lg"
              to="/customers"
              onClick={() => {
                this.props.updateNavBar("/customers");
                ReactGA.event({
                  category: "button",
                  action: "Went to customer portal from splash",
                });
              }}
            >
              CUSTOMER PORTAL
            </Link>
          </div>
        </div>
        <div className="splash-content">
          <div className="splash state-wrap">
            <p className="vervent-info body">
              Please be advised that ThunderRoad Financial utilizes Vervent, a
              third party servicer to handle payment processing, payoffs, title
              requests, payment inquiries and customer service issues. It has
              been brought to our attention that there have been delays with
              some of our customers’ requests along with long wait times on the
              Vervent-operated customer service numbers. We are working to
              alleviate these inconveniences for our customers and hope to work
              with Vervent to promptly resolve the issues. However in the
              meantime , if you are having issues with your account that have
              not been properly addressed, please email us at
              complaints@thunderroadfinancial.com. Please provide your name,
              address, the last 4 digits of your account, current phone number
              at which you would like to be contacted, the times of day you
              would like to be called back and the issue you are having.<b> Please
              do <i>not</i> include Social Security Number, Driver’s License or any
              other non-public information.</b> One of our corporate employees will
              get back to you as soon as possible. Thank you for your patience.
            </p>
            <h5 className="covid-info body">
              ThunderRoad Financial is here to help our customers and fellow
              riders during this trying time. We understand that our riders
              might be in need of assistance and we are here to help. If you are
              currently experiencing financial hardship due to the COVID-19
              outbreak please contact us at (775) 332-1965 or email us at
              info@thunderroadfinancial.com. Please understand due to the five
              star service we strive to provide, there could be a slight delay
              in responses due to increased call volumes. We appreciate your
              patience and hope everyone is doing well.
            </h5>
            <h2 className="splash services-header white xxl">
              Now Accepting Dealers in the Following States
            </h2>
            <div className="splash column body">
              <p className="state body">Alabama</p>
              <p className="state body">Arizona</p>
              <p className="state body">California</p>
              <p className="state body">Florida</p>
              <p className="state body">Georgia</p>
              <p className="state body">Missouri</p>
              <p className="state body">Montanta</p>
              <p className="state body">Nevada</p>
              <p className="state body">North Carolina</p>
              <p className="state body ">Oklahoma</p>
              <p className="state body">Oregon</p>
              <p className="state body">South Carolina</p>
              <p className="state body">Tennessee</p>
              <p className="state body">Texas</p>
              <p className="state body">Virginia</p>
              <p className="state body">Washington</p>
            </div>

            <Link
              className="dealer-login-button-text-light lg"
              to={{ pathname: "/dealers", state: { scrollRef: "form" } }}
              onClick={() => {
                this.props.updateNavBar("/dealers");
                ReactGA.event({
                  category: "button",
                  action: "went to contact form from splash",
                });
              }}
            >
              Sign Up Today
            </Link>
          </div>
          <div className="copy-wrap">
            <div className="splash-services">
              <h2 className="splash services-header xxl">What We Do</h2>
              <div className="splash services-content body">
                <p>
                  ThunderRoad is the premier, indirect lender in the PowerSports
                  industry with a focus on motorcycles and titled ATVs. Led by a
                  qualified team, ThunderRoad is contracted with 450+ dealers
                  across 28 states. We have been providing top-tier customer
                  service, professional underwiting, and flexible financing
                  solutions to our dealership partners and customers since 2014.
                </p>
              </div>
            </div>
            <div className="splash-services">
              <h2 className="splash services-header xxl">SERVICES</h2>
              <div className="splash services-content body">
                <p>
                  ThunderRoad never auto-accepts. We understand that each deal
                  is unique, so we invest the time to make a personal
                  relationship with each customer and dealer. Our intuitive
                  credit application, and 24-72 hour funding timeframe, keeps
                  the process <i>quick</i> and <i>easy</i> for dealers and
                  customers alike. It's impossible to find a new <i>classic</i>,
                  so ThunderRoad finances both new and used vehicles.
                </p>
              </div>
            </div>
            <div className="splash data-graphic" style={dataGraphicBGStyle}>
              <div className="splash statistics">
                <div className="stat-wrap">
                  <p className="stat-value xlg">450+</p>
                  <p className="stat">Dealers Enrolled</p>
                </div>
                <div className="stat-wrap">
                  <p className="stat-value xlg">28</p>
                  <p className="stat">Active States</p>
                </div>
                <div className="stat-wrap">
                  <p className="stat-value xlg">55+</p>
                  <p className="stat">years of executive experience</p>
                </div>
              </div>
            </div>
            <div className="splash-values">
              <h2 className="splash values-header xxl">CORE VALUES</h2>
              <div className="splash values-content">
                <div className="value-wrapper">
                  <img
                    className="value-icon"
                    alt="communication-icon"
                    src={cust}
                  />
                  <div className="value-item-text">
                    <h3 className="value-content-header med">
                      OPEN COMMUNICATION
                    </h3>
                    <p className="value-content-text body">
                      Great partnerships deserve great communication. With over
                      450 dealers, we make sure each ones' voice is heard
                    </p>
                  </div>
                </div>
                <div className="value-wrapper">
                  <img
                    className="value-icon"
                    alt="relationship-icon"
                    src={partner}
                  />
                  <div className="value-item-text">
                    <h3 className="value-content-header med">
                      PERSONAL RELATIONSHIPS WITH OUR DEALERS
                    </h3>
                    <p className="value-content-text body">
                      A customer-first approach means a personal relationship
                      with each and every one of our dealerships
                    </p>
                  </div>
                </div>
                <div className="value-wrapper">
                  <img
                    className="value-icon"
                    alt="professionalism-icon"
                    src={leader}
                  />
                  <div className="value-item-text">
                    <h3 className="value-content-header med">
                      PROFESSIONALISM & ENTHUSIASM
                    </h3>
                    <p className="value-content-text body">
                      Our team is built on over 75 years of powersports
                      financing knowledge to provide the best dealer experience
                      in the industry
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="splash why-wrap">
            <h2 className="splash why-header x">WHY THUNDERROAD?</h2>
            <div className="splash why-content body">
              ThunderRoad knows each deal is unique so we have a skilled team of
              underwriters involved on every loan application. Our dedicated
              team is available at every step of the process to answer
              questions, provide explanation, and ensure complete satisfaction.
              <br />
              <br />
              <br />
              "They have been able to provide us with additional financial
              solutions for our customers that has allowed us to retain more
              business and increase our profit!"
              <br />
              <br />
              <i>- General Manager, LA Harley Davidson </i>
              <br />
              <br />
              <br />
              With decades-long relationships with powersports dealers,
              ThunderRoad has seen the importance in pursuing custom solutions
              for each of our dealers. Every situation is unique and we
              understand that. That's why we'll continuously work with you to
              always find the best financing option.
            </div>
          </div>

          <div className="splash our-team">
            <h1 className="team-section-header xxl">OUR TEAM</h1>
            <div className="leader-section">
              <div className="leader-header">
                <h3 className="leader-name med">Donal Hummer Jr.</h3>
                <h5 className="leader-title smll">President and CEO</h5>
              </div>
              <div className="leader-bio body">
                In 2014 Donal put together a group of like-minded investors and
                dedicated employees and launched ThunderRoad Financial services.
                He serves as the President and CEO. ThunderRoad Financial
                services is a premier powersports financial services company
                that fills the void between captive finance companies and large
                financial institutions. Providing the powersports dealers with
                competitive financing, ancillary products, along with exemplary
                service and an experienced, knowledgeable staff, that is second
                to none.
                <br />
                <br />
                In 2017 he was elected Chairman of the Powersports Finance Board
                of Advisors.
              </div>
            </div>
            <div className="leader-section">
              <div className="leader-header">
                <h3 className="leader-name med">Kristi Mercier</h3>
                <h5 className="leader-title smll">Chief Operations Officer</h5>
              </div>
              <div className="leader-bio body">
                In her role as Chief Operations Officer for ThunderRoad
                Financial, Kristi Mercier prides herself in driving results
                through collaboration, partnerships, and relationships. With an
                enthusiastic and genuinely friendly attitude, Kristi radiates a
                sincere passion for delivering value and benefits to OEMs,
                dealers, applicants and fellow co-workers.
                <br />
                <br />
                In 2018 she joined the Powersports Finance Board of Advisors.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
