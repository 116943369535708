import React from "react";
import trbg from "../images/TRbg.jpeg";
import CustAcct from "../components/CustAcct";

import "../App.css";

export default class CustomersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acctSelect: "none",
      acctHours: "",
      acctHours2: "",
      acctHours3: "",
      regMail1: "",
      regMail2: "",
      mail: "",
      mail2: "",
      mail3: "",
      mail4: "",
      phoneNum: "",
      acctStyle: "",
      payPortal: ""
    };
  }

  handleClick = () => {
    console.log("pressed");
    this.setState({
      acctSelect: "507 acct",
      acctHours: "Mon - Fri: 8:00AM - 8:00PM (EST)",
      acctHours2: "Sat: 11:00AM - 3:00PM (EST), Sun: CLOSED",
      email: "customerservice@thunderroadfinancial.com",
      regMail1: "P.O. Box 842762",
      regMail2: "Dallas, TX 75284-2762",
      mail: "Bank of America Lockbox Service,",
      mail2: "LockBox 842762",
      mail3: "1950 North Stemmons Freeway",
      mail4: "Dallas, TX 75207",
      phoneNum: "(833) 329-0525",
      acctStyle: "a1",
      payPortal: "https://ipn2.paymentus.com/cp/thrd"
    });
    console.log(this.state);
  };

  handleTAClick = () => {
    console.log("BIG pressed");
    this.setState({
      acctSelect: "TA acct",
      acctHours: "Mon - Fri: 6:00AM - 7:00PM (PST)",
      acctHours2: "Sat: 7:00AM - 12:00PM (PST)",
      acctHours3: "",
      email: "ThunderRoadCustomerService@acct-admin.com",
      regMail1: "P.O Box 504125",
      regMail2: "San Diego, CA 92150-4125",
      mail: "9775 Clairemont Mesa Blvd.",
      mail2: "San Diego, California 92124",
      mail3: "",
      mail4: "",
      phoneNum: "(855) 677-6469",
      acctStyle: "a2",
      payPortal: "https://myaccount.1stassociates.com"
    });
    console.log(this.state);
  };

  render() {
    var headerBGStyle = {
      backgroundImage: "url(" + trbg + ")",
      backgroundSize: "cover",
      backgroundPosition: "center"
    };

    return (
      <div className="cust-page">
        <div className="cust-hero">
          <div className="cust-image" style={headerBGStyle}>
            <div className="hero-wrap">
              <div className="acct-box hero">
                <h2 className="cust-header xxl">
                  {" "}
                  OUR COMMITTMENT TO OUR CUSTOMERS IS UNMATCHED{" "}
                </h2>
                <div className="cust-text hero sub ">
                  our customer account portals can be found below. If you have
                  any questions or concerns, let's talk and we'll make it right
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className="cust-accts head-txt"> ACCESS ACCOUNTS </h3>
        </div>
        <div className="acct-btns-wrap w">
          <div className="acct-helper-txt body">
            If your account number begins with "507", please click on the tab
            below.
          </div>
          <div className="acct-helper-txt body">
            If your account number begins with a "TA" or "TR", please click on
            the tab below
          </div>
        </div>

        <div className="acct-btns-wrap">
          <div
            className="acct-btn-box g"
            onClick={() => {
              this.handleClick();
            }}
          >
            <div
              className={
                this.state.acctStyle === "a1"
                  ? "acct-btn-text ul"
                  : "acct-btn-text"
              }
            >
              Accounts that begin with "507"
            </div>
          </div>

          <div
            className="acct-btn-box b"
            onClick={() => {
              this.handleTAClick();
            }}
          >
            <div
              className={
                this.state.acctStyle === "a2"
                  ? "acct-btn-text ul"
                  : "acct-btn-text"
              }
            >
              Accounts that begin with "TA" or "TR"
            </div>
          </div>
        </div>
        <div className="a1 p">
          <div className={this.state.acctSelect === "TA acct" ? "a2" : "a1"}>
            <CustAcct
              phoneNum={this.state.phoneNum}
              acctHours={this.state.acctHours}
              acctHours2={this.state.acctHours2}
              acctHours3={this.state.acctHours3}
              email={this.state.email}
              regMail1={this.state.regMail1}
              regMail2={this.state.regMail2}
              mail={this.state.mail}
              mail2={this.state.mail2}
              mail3={this.state.mail3}
              mail4={this.state.mail4}
              acctSelect={this.state.acctSelect}
              acctStyle={this.state.acctStyle}
              payPortal={this.state.payPortal}
            />
          </div>
        </div>
      </div>
    );
  }
}
