import React from "react";
import NewsArticleList from "../components/NewsArticleList";
import trbg from "../images/TRbg.jpeg"
import Thunder1 from "../images/Thunder1.JPG";
import Thunder2 from "../images/Thunder2.JPG";
import Thunder3 from "../images/Thunder3.JPG";
import Thunder4 from "../images/Thunder4.JPG";
import Thunder5 from "../images/Thunder5.JPG";
import Thunder6 from "../images/Thunder6.JPG";
import Thunder7 from "../images/Thunder7.JPG";
import Thunder8 from "../images/Thunder8.JPG";
import Thunder9 from "../images/Thunder9.JPG";
import Thunder10 from "../images/Thunder10.JPG";
import Thunder11 from "../images/Thunder11.JPG";
import Thunder12 from "../images/Thunder12.JPG";
import Thunder13 from "../images/Thunder13.JPG";
import Thunder14 from "../images/Thunder14.JPG";
import Thunder15 from "../images/Thunder15.JPG";

import TR1mobile from "../images/TR1-mobile.jpg";
import TR2mobile from "../images/TR2-mobile.jpg";
import TR3mobile from "../images/TR3-mobile.jpg";
import TR4mobile from "../images/TR4-mobile.jpg";
import TR5mobile from "../images/TR5-mobile.jpg";
import TR6mobile from "../images/TR6-mobile.jpg";
import TR7mobile from "../images/TR7-mobile.jpg";
import TR8mobile from "../images/TR8-mobile.jpg";
import TR9mobile from "../images/TR9-mobile.jpg";
import TR10mobile from "../images/TR10-mobile.jpg";
import TR11mobile from "../images/TR11-mobile.jpg";
import TR12mobile from "../images/TR12-mobile.jpg";
import TR13mobile from "../images/TR13-mobile.jpg";
import TR14mobile from "../images/TR14-mobile.jpg";
import TR15mobile from "../images/TR15-mobile.jpg";



const articles = [
  {
    id: 1,
    image: Thunder1,
    image2:TR1mobile ,
    link:
      "https://powersportsfinance.com/thunderroad-wells-fargo-tdecu-optimistic-about-market-in-2020/"
  },

  {
    id: 2,
    image: Thunder2,
    image2: TR2mobile,
    link:
      "https://powersportsfinance.com/thunderroad-partners-with-digital-investment-platform-to-grow-capital/"
  },

  {
    id: 3,
    image: Thunder3,
    image2: TR3mobile,
    link:
      "https://powersportsfinance.com/thunderroad-launches-personal-watercraft-pilot/"
  },

  {
    id: 4,
    image: Thunder4,
    image2: TR4mobile,
    link:
      "https://powersportsfinance.com/thunderroad-to-present-sales-and-marketing-workshop-at-powersports-finance-summit/"
  },

  {
    id: 5,
    image: Thunder5,
    image2: TR5mobile,
    link:
      "https://powersportsfinance.com/thunderroad-hires-sales-director-to-double-dealer-network/"
  },

  {
    id: 6,
    image: Thunder6,
    image2: TR6mobile,
    link:
      "https://powersportsfinance.com/thunderroads-kristi-mercier-to-present-best-practices-at-powersports-finance-summit/"
  },

  {
    id: 7,
    image: Thunder7,
    image2: TR7mobile,
    link:
      "https://powersportsfinance.com/improved-economy-spurs-lower-delinquencies-at-thunderroad/"
  },

  {
    id: 8,
    image: Thunder8,
    image2: TR8mobile,
    link:
      "https://powersportsfinance.com/thunderroad-relaunches-full-spectrum-program/"
  },

  {
    id: 9,
    image: Thunder9,
    image2: TR9mobile,
    link:
      "https://powersportsfinance.com/thunderroad-financial-pilots-deep-subprime-program/"
  },

  {
    id: 10,
    image: Thunder10,
    image2: TR10mobile,
    link:
      "https://powersportsfinance.com/thunderroad-loan-volume-increases-for-side-by-sides-indian-motorcycles/"
  },

  {
    id: 11,
    image: Thunder11,
    image2: TR11mobile,
    link:
      "https://powersportsfinance.com/thunderroad-boosts-approval-rate-with-dealer-education-shorter-terms/"
  },

  {
    id: 12,
    image: Thunder12,
    image2: TR12mobile,
    link:
      "https://powersportsfinance.com/electric-bike-oem-signs-thunderroad-as-retail-finance-lender/"
  },

  {
    id: 13,
    image: Thunder13,
    image2: TR13mobile,
    link:
      "https://powersportsfinance.com/thunderroad-financial-adds-docusign-for-faster-financing/"
  },

  {
    id: 14,
    image: Thunder14,
    image2: TR14mobile,
    link:
      "https://powersportsfinance.com/thunderroad-grows-originations-25-with-credit-union-partnership/"
  },

  {
    id: 15,
    image: Thunder15,
    image2: TR15mobile,
    link:
      "https://powersportsfinance.com/thunderroad-and-motolease-execs-to-speak-on-credit-trends-at-powersports-finance-2018/"
  }
];

export default class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsArticles: articles

    }
  }
  render() {

    var headerBGStyle = {
      backgroundImage: "url(" + trbg + ")",
      backgroundSize: "cover",
      backgroundPosition: "center"
    };

    return (
      <div className="news-page">
        <div className="news-hero" style={headerBGStyle}>
          <div className="dealer-hero-wrap">
            <div className="dealer-hero-text">
              <h3 className="dealer-hero-header head-txt">
              KEEP UP TO DATE WITH LIGHTNING NEWS
              </h3>
            </div>
          </div>
        </div>
        <NewsArticleList articleInfo={this.state.newsArticles} />
      </div>
    );
  }
}
