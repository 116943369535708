import React from "react";
import { Link } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa';

const NorthCarolina = [
    { name: "Baker American Cycles", number: "(910) 423-1901", address: "1508 Hope Mills Road, FAYETTEVILLE, NC, 28304" },
    { name: "Capital Honda Powersports", number: "(919) 719-0700", address: "10920 Star Road, WAKE FOREST, NC, 27587" },
    { name: "Cox's Harley Davidson", number: "(336) 629-2415", address: "2975 NC Hwy 134, ASHEBORO, NC, 27205" },
    { name: "Cyclemax Wilson", number: "(252) 291-2121", address: "6723 Ward Blvd, WILSON, NC, 27893" },
    { name: "Cox's Double Eagle Harley Davidson", number: "(910) 420-1564", address: "7540 NC Highway 15, WEST END, NC, 27376" },
    { name: "Fast Times Motors LLC", number: "(919) 865-3240", address: "9008 Glenwood Ave., RALEIGH, NC, 27617" },
    { name: "Lejeune Motorsports", number: "(910) 577-1788", address: "955 Lejeune BLVD, JACKSONVILLE, NC, 28540" },
    { name: "L and L Performance", number: "(919) 775-2535", address: "1871 Riddle Rd, SANFORD, NC, 27330" },
    { name: "No Limit MotorSports", number: "(704) 913-9886", address: "124 HUBBARD ST., BELMONT, NC, 28012" },
    { name: "Kevin Powells Forsyth Motorsports", number: "(336) 767-2020", address: "5599 University Pkwy, WINSTON SALEM, NC, 27105" },
    { name: "Kevin Powells Greensboro Motorsports", number: "(336) 852-4228", address: "6371 Burnt Poplar Rd, GREENSBORO, NC, 27409" },
    { name: "Kevin Powells Pineville Motorsports", number: "(704) 889-3500", address: "502 N. Polk St, PINEVILLE, NC, 28134" },
    { name: "Ride Now Powersports Concord", number: "(704) 784-5400", address: "254 Concord Pkwy South, CONCORD, NC, 28027" },
    { name: "Select Cycle", number: "(336) 271-4774", address: "431 Battleground Avenue, GREENSBORO, NC, 27401" },
    { name: "Six Gear Moto", number: "(336) 281-2006", address: " 2450 Corporation pkwy, BURLINGTON, NC, 27215" },
    { name: "Smokin Harley Davidson", number: "(336) 722-3106", address: "3441 Myers Lee Dr., WINSTON SALEM, NC, 27101" },
    { name: "Speedway Harley Davidson", number: "(704) 979-7433", address: "10049 Weddington Rd, CONCORD, NC, 28027" },
    { name: "Sport Cycles", number: "(910) 582-8500", address: "749 HWY 74 East, ROCKINGHAM, NC, 28379" },
    { name: "Sport Durst Powersports", number: "(919) 489-7478", address: "4503 Durham Chapel Hill Blvd., Durham, NC, 27767" },
    { name: "SRS MOTORSPORTS ", number: "(336) 375-5311", address: "3122 SANDS DR., GREENSBORO, NC, 27405" },
    { name: "Team Charlotte Motorsports", number: "(704) 394-7301", address: "3004 Freedom Dr., CHARLOTTE, NC, 28208" },
]
const SouthCarolina = [
    { name: "17 Customs", number: "(843) 281-0525", address: "724 Hwy 17, LITTLE RIVER, SC, 29566" },
    { name: "MAIN STREET POWERSPORTS", number: "(843) 285-9854", address: "2026-B N. MAIN ST., SUMMERVILLE, SC, 29486" },
    { name: "Ol Reds Motorcycles", number: "(864) 757-1903", address: "1211 NE. Main ST., SIMPSONVILLE, SC, 29681" },
    { name: "Performance PowerSports Seneca", number: "(864) 888-8123", address: "329 By Pass 123, SENECA, SC, 29678" },
    { name: "Timms Harley Davidson", number: "(864) 224-1531", address: "4110 Clemson Blvd, ANDERSON, SC, 29621" },
]
const Florida = [
    { name: "ALL OUT POWERSPORTS", number: "(386) 310-7884", address: "420 N. Beach ST, DAYTONA BEACH, FL, 32114" },
    { name: "Barney's Motorcycle Sales", number: "(727) 576-1148", address: "10411 Gandy Blvd, SAINT PETERSBURG, FL, 33702" },
    { name: "Barney's Yamaha Suzuki of Brandon", number: "(813) 628-9418", address: "9820 Adamo Dr, TAMPA, FL, 33619" },
    { name: "Barneys of Brookville", number: "(352) 796-6116", address: "14430 Cortez Blvd, BROOKSVILLE, FL, 34613" },
    { name: "Beach Boulevard MotorSports", number: "(904) 641-0066", address: "10315 Beach Blvd., JACKSONVILLE, FL, 32246" },
    { name: "BMW Ducati Triumph Of Daytona", number: "(386) 257-2269", address: "118 E Fairview ave, DAYTONA BEACH, FL, 32118" },
    { name: "Brandon Harley Davidson Shop", number: "(813) 740-9898", address: "9841 E. Adamo Dr, TAMPA, FL, 33619" },
    { name: "Broward Motorsports of Ft. Lauderdale", number: "(954) 467-0990", address: "1090 W. Sunrise Blvd., FORT LAUDERDALE, FL, 33311" },
    { name: "Cahill's Motorsports, LLC", number: "(813) 788-1779", address: "8820 Gall Blvd, ZEPHYRHILLS, FL, 33541" },
    { name: "Cahill's of North Tampa", number: "(813) 933-3528", address: "8920 N. Armenia Ave, TAMPA, FL, 33604" },
    { name: "Central Florida Power Sports", number: "(407) 847-2453", address: "2575 N. Orange Blossom Trl, KISSIMMEE, FL, 34744" },
    { name: "Crystal Harley Davidson", number: "(352) 563-9900", address: "1785 S. SUNCOAST BLVD., HOMOSASSA, FL, 34448" },
    { name: "Rossmeyer's Daytona Harley Davidson", number: "(386) 671-7100", address: "1637 N US Hwy 1, ORMOND BEACH, FL, 32174" },
    { name: "Deland Motorsports", number: "(386) 740-2453", address: "2610 S. Woodland Blvd., DELAND, FL, 32720" },
    { name: "Eagle Rider Sales Ft. Lauderdale", number: "(954) 514-7230", address: "1880 S. Federal Hwy, FORT LAUDERDALE, FL, 33316" },
    { name: "Ellite Motor Sports", number: "(561) 926-3470", address: "160 Commerce Road, BOYNTON BEACH, FL, 33426" },
    { name: "Emerald Coast Harley Davidson", number: "(850) 937-7070", address: "877 Beal Pkwy NW, FORT WALTON BEACH, FL, 32547" },
    { name: "Fun Bike Center Motorsports", number: "(863) 688-3333", address: "1845 E. Memorial Blvd, LAKELAND, FL, 33801" },
    { name: "Gables Motorsports", number: "(305) 266-8300", address: "7300 SW 40th St, MIAMI, FL, 33155" },
    { name: "Performance Honda", number: "(813) 973-1888", address: "28009 Wesley Chapel Blvd, WESLEY CHAPEL, FL, 33543" },
    { name: "Harley Davidson of New Port Richey", number: "(727) 842-4547", address: "5817 State Road 54, NEW PORT RICHEY, FL, 34652" },
    { name: "Harley Davidson of Panama City Beach", number: "(850) 334-0055", address: "14700 Panama City Beach Pkwy, PANAMA CITY BEACH, FL, 32413" },
    { name: "Holeshot Powersports", number: "(904) 863-3131", address: "6945 US HWY, FLEMING ISLAND, FL, 32003" },
    { name: "IMotorsports SPB", number: "(727) 456-6088", address: "555 34th st. S, SAINT PETERSBURG, FL, 33711" },
    { name: "Indian Motorcycle Orlando", number: "(407) 251-5335", address: "1150 Jetport Drive, ORLANDO, FL, 32809" },
    { name: "Indian Motorcyle of Panama City Beach", number: "(850) 819-3022", address: "13830 Panama City Beach Parkway PANAMA CITY BEACH, FL, 32407" },
    { name: "Iron Eagle Sports LLC", number: "(863) 676-9505", address: "14105 HWY 27 South, LAKE WALES, FL, 33859" },
    { name: "Jim Walker Motorcycles", number: "(386) 761-2411", address: "2385 S. RIDGEWOOD AVE. DAYTONA BEACH, FL, 32119" },
    { name: "Kissimmee Motorsports", number: "(407) 847-6686", address: "2881 N John Young Pkwy., KISSIMMEE, FL, 34741" },
    { name: "Harley Davidson Lakeland", number: "(863) 802-1971", address: "4202 Lakeland Hills Blvd, LAKELAND, FL, 33805" },
    { name: "Lighting Powersports", number: "(727) 842-6624", address: "8822 US Hwy 19, PORT RICHEY, FL, 34668" },
    { name: "MCKIBBEN POWERSPORTS HONDA", number: "(863) 293-1279", address: "3699 US HWY 17 N, WINTER HAVEN, FL, 33881" },
    { name: "Indian Motorcycle of Miami", number: "(305) 448-2989", address: "3850 South Dixie Highway, MIAMI, FL, 33133" },
    { name: "Moramoto LLC", number: "(727) 800-2944", address: "7000 Park Blvd, PINELLAS PARK, FL, 33781" },
    { name: "Motorcycle Enthusiasts", number: "(352) 596-8028", address: "5138 Commercial Way, SPRING HILL, FL, 34606" },
    { name: "Mr MotorHead Inc.", number: "(954) 909-5870", address: "2740 N Federal Hwy, FORT LAUDERDALE, FL, 33306" },
    { name: "Rossmeyer's New Smyrna Harley Davidson", number: "(386) 409-3034", address: "1899 SR 44, NEW SMYRNA BEACH, FL, 32168" },
    { name: "PALM BEACH HARLEY DAVIDSON", number: "(561) 659-4131", address: "2955 45th St, WEST PALM BEACH, FL, 33407" },
    { name: "Harley Davidson of Pensacola", number: "(850) 494-1224", address: "6385 Pensacola Blvd, PENSACOLA, FL, 32505" },
    { name: "Powerplay Motorsports", number: "(407) 732-6893", address: "555 Dog Track Rd., LONGWOOD, FL, 32750" },
    { name: "Purcell's Motorcycle of Marine World", number: "(904) 771-8244", address: "6407 Blanding Blvd., JACKSONVILLE, FL, 32244" },
    { name: "Pure Powersports", number: "(904) 406-8800", address: "550 STATE ROAD 207, SAINT AUGUSTINE, FL, 32084" },
    { name: "Powersports of Palm Beach", number: "(561) 844-2102", address: "1422 10th St., WEST PALM BEACH, FL, 33403" },
    { name: "RC Hill Honda of Deland", number: "(386) 873-6772", address: "560 S. Woodlands, DELAND, FL, 32720" },
    { name: "RideNow Gainesville", number: "(352) 376-2637", address: "4820 NW 13th Street, GAINESVILLE, FL, 32609" },
    { name: "RideNow Powersports Ocala", number: "(352) 732-8531", address: "3880 N. US HIGHWAY 441, OCALA, FL, 34475" },
    { name: "Indian RNMC Daytona Beach ", number: "(386) 275-1660", address: "290 N Beach Street, DAYTONA BEACH, FL, 32114" },
    { name: "Santos Cycles", number: "(980) 292-5371", address: "566 Ballough Rd., DAYTONA BEACH, FL, 32114" },
    { name: "Sarasota Powersports", number: "(941) 351-4330", address: "2001 University Parkway, SARASOTA, FL, 34243" },
    { name: "Sky Powersports of Lakeland", number: "(863) 682-4607", address: "1638 Kathleen Road, LAKELAND, FL, 33805" },
    { name: "Suzuki Of Lake Wales", number: "(863) 676-2245", address: "20769 Hwy 27, LAKE WALES, FL, 33853" },
    { name: "Sky Powersports North Orlando", number: "(407) 960-6826", address: "855 N Hwy 17-92, LONGWOOD, FL, 32750" },
    { name: "SPACE COAST Harley Davidson", number: "(321) 259-1311", address: "1440 SPORTSMAN LANE NE, PALM BAY, FL, 32905" },
    { name: "Spanos Cycles", number: "(386) 323-3777", address: "921 W Intl. Speedway, DAYTONA BEACH, FL, 32114" },
    { name: "Tallahassee Harley Davidson", number: "(850) 205-4294", address: "1745 Capital Circle NW, TALLAHASSEE, FL, 32303" },
    { name: "Tampa Bay Powersports, LLC", number: "(813) 968-7888", address: "13521 N. Florida Ave, TAMPA, FL, 33613" },
    { name: "Tampa Harley Davidson", number: "(813) 886-7433", address: "6920 N. Dale Mabry Highway, TAMPA, FL, 33614" },
    { name: "Tampa Triumph", number: "(813) 523-4693", address: "7000 N. Dale Mabry Hwy, TAMPA, FL, 33614" },
    { name: "Traders Motorsports", number: "(863) 956-3414", address: "670 S. Lake Shore Way, LAKE ALFRED, FL, 33850" },
    { name: "TRG Motor Company", number: "(844) 304-2453", address: "5640 Dewey Street suite A, HOLLYWOOD, FL, 33023" },
    { name: "TRIPLE TRIUMPH MIAMI", number: "(786) 621-1105", address: "4751 WEST FLAGLER ST., MIAMI, FL, 33134" },
    { name: "Tropic PowerSports", number: "(239) 690-2925", address: "3462 Fowler ST., FORT MYERS, FL, 33901" },
    { name: "Velocity Motorworx", number: "(321) 345-4918", address: "855 S. Wickham RD., MELBOURNE, FL, 32904" },
    { name: "Volusia Motortsports", number: "(386) 428-2212", address: "1701 State Rd. 44, NEW SMYRNA BEACH, FL, 32168" },
    { name: "The Original VTwin City", number: "(754) 307-3728", address: "1771 E Sunrise Blvd, FORT LAUDERDALE, FL, 33304" },
    { name: "War Horse Harley Davidson", number: "(352) 732-2488", address: "5331 N US 441, OCALA, FL, 34475" },
    { name: "West Coast Powersports", number: "(727) 536-4791", address: "14996 U.S. Highway 19N, CLEARWATER, FL, 33764" },
    { name: "WMR Competition Performance", number: "(772) 426-3000", address: "7749 SW Ellipse Way, STUART, FL, 34997" },
]
const Georgia = [
    { name: "ADRENALIN POWERSPORTS", number: "(770) 233-4607", address: "1218 ENTERPRISE WAY, GRIFFIN, GA, 30224" },
    { name: "Extreme Powersports", number: "(706) 324-0132", address: "2990 Northlake Parkway, COLUMBUS, GA, 31909" },
    { name: "Freedom Powersports Canton", number: "(770) 720-9554", address: "645 Riverside Pkwy, CANTON, GA, 30114" },
    { name: "Freedom Powersports Alpharetta", number: "(770) 752-9160", address: "5960 Atlanta Hwy, ALPHARETTA, GA, 30004" },
    { name: "Freedom Powersports McDonough", number: "(770) 957-7404", address: "755 Industrial Blvd., MCDONOUGH, GA, 30253" },
    { name: "North Georgia Choppers", number: "(706) 946-1811", address: "1674 Appalachian Hwy, BLUE RIDGE, GA, 30513" },
    { name: "Hellbender Harley Davidson", number: "(770) 919-0000", address: "993 S. Cobb Dr, MARIETTA, GA, 30060" },
    { name: "Honda Yamaha of Savannah", number: "(912) 927-7070", address: "11512 Abercorn St., SAVANNAH, GA, 31419" },
    { name: "Honda South Georgia", number: "(229) 396-4050", address: "1025 Camden Way, TIFTON, GA, 31794" },
    { name: "Indian Motorcycles of Marietta", number: "(770) 956-9577", address: "1750 Cobb Parkway, MARIETTA, GA, 30060" },
    { name: "Killer Creek Harley-Davidson", number: "(770) 777-1000", address: "11480 Alpharetta Hwy, ROSWELL, GA, 30076" },
    { name: "Mall of Georgia Indian Motorcycle", number: "(470) 655-0566", address: "2745 Mall of Georgia BLVD., BUFORD, GA, 30519" },
    { name: "Marietta SportsCar", number: "(770) 420-8787", address: "1616 Roswell Rd., MARIETTA, GA, 30062" },
    { name: "Savannah Powersport Center", number: "(912) 920-2626", address: "5918 Ogeechee Rd Suite H, SAVANNAH, GA, 31419" },
    { name: "SouthEast Motorcycle", number: "(912) 965-0505", address: "4828 Augusta Rd, SAVANNAH, GA, 31408" },
    { name: "Statesboro Powersport Center", number: "(912) 764-2587", address: "22681 Hwy 80 East, STATESBORO, GA, 30461" },
    { name: "Timms HD of Augusta", number: "(706) 651-0444", address: "4200 Belair Frontage Road, AUGUSTA, GA, 30909" },
    { name: "WOW Motorcycles", number: "(770) 424-8804", address: "508 Cobb Pkwy., MARIETTA, GA, 30062" },
]
const Alabama = [
    { name: "Big #1 Motorsports", number: "(205) 942-3313", address: "505 Cobb St, BIRMINGHAM, AL, 35209" },
    { name: "Freedom Powersports Huntsville", number: "(256) 650-1177", address: "12401 S. Memorial Pkwy, HUNTSVILLE, AL, 35803" },
    { name: "Halls Motorsports", number: "(251) 509-0999", address: "1520 W I-65 Service Rd, MOBILE, AL, 36693" },
    { name: "Harley Davidson of Montgomery", number: "(334) 245-6555", address: "655 North Eastern Blvd, MONTGOMERY, AL, 36117" },
    { name: "Mobile Bay Harley Davidson", number: "(251) 471-2174", address: "3260 Pleasant Valley Road, MOBILE, AL, 36606" },
    { name: "Pinnacle Motorsports", number: "(205) 428-6266", address: "113 19th St. North, BESSEMER, AL, 35020" },
    { name: "The Trike Man", number: "(251) 510-9426", address: "12451 Jeff Hamilton, MOBILE, AL, 36695" },
]
const Texas = [
    { name: "Ace Moto", number: "(915) 533-6686 ", address: "3330 Gateway E, EL PASO, TX, 79905" },
    { name: "Action Suzuki", number: "(972) 686-8000", address: "3010 I-30, MESQUITE, TX, 75150" },
    { name: "Adam Smiths Texas Harley Davidson", number: "(817) 267-2646", address: "1921 Shoalmont Rd., BEDFORD, TX, 76021" },
    { name: "Alamo Harley Davidson", number: "(210) 646-0499", address: "11005 N. Interstate 35, SAN ANTONIO, TX, 78233" },
    { name: "Arlington Motorsports", number: "(817) 695-1600", address: "501 Centennial Dr. Suite 100, ARLINGTON, TX, 76011" },
    { name: "Bikes 2 Trikes Motorsports", number: "(817) 237-1900", address: "7224 Red Bud Ln, FORT WORTH, TX, 76135" },
    { name: "Buyyourmotorcycle.com", number: "(817) 649-1905", address: "185 S. Watson Rd #107, ARLINGTON, TX, 76010" },
    { name: "Calculated Risk Texoma", number: "(903) 892-2530", address: "4000 N US 75, SHERMAN, TX, 75090" },
    { name: "Carrollton Kawasaki", number: "(972) 418-6565", address: "2655 E. Beltline, CARROLLTON, TX, 75006" },
    { name: "Corpus Christi Cycle Plaza", number: "(361) 852-7368", address: "2937 S Padre Island, CORPUS CHRISTI, TX, 78415" },
    { name: "Central Texas Powersports", number: "(512) 948-9922", address: "2534 N Austin Ave, GEORGETOWN, TX, 78626" },
    { name: "Central Texas Harley Davidson", number: "(512) 652-1200", address: "2801 n. IH-35, ROUND ROCK, TX, 78664" },
    { name: "CORPUS CHRISTI CYCLE SPORTS", number: "(361) 854-3146", address: "5025. PADREISLAND DRIVE, CORPUS CHRISTI, TX, 78405" },
    { name: "Cowboy Harley Davidson of Austin", number: "(512) 448-4294", address: "10917 S. Interstate 35, AUSTIN, TX, 78747" },
    { name: "Cowboy Harley Davidson of Beaumont", number: "(409) 840-6969", address: "1150 S.IH 10, BEAUMONT, TX, 77701" },
    { name: "Cowboy Powersports", number: "(409) 832-5971", address: "1280 IH-10 South, BEAUMONT, TX, 77707" },
    { name: "Destination Cyclesports", number: "(830) 896-2453", address: "3341 A Loop 534, KERRVILLE, TX, 78028" },
    { name: "DFW Honda", number: "(817) 421-4663", address: "2350 William D. Tate Ave, GRAPEVINE, TX, 76051" },
    { name: "D'Moto Powersports", number: "(210) 403-2100", address: "12066 Starcrest Dr. Ste 110, SAN ANTONIO, TX, 78247" },
    { name: "Dream Machines Indian Motorcycle", number: "(512) 735-5151", address: "1401 N I35, ROUND ROCK, TX, 78664" },
    { name: "Dream Machines of Texas", number: "(972) 380-5151", address: "13600 N. Stemmons, DALLAS, TX, 75234" },
    { name: "Ducati San Antonio", number: "(210) 403-2100", address: "4918 Fredericksburg Rd., SAN ANTONIO, TX, 78229" },
    { name: "Family Powersports Odessa", number: "(432) 368-7907", address: "4306 N ANdrews HWY, ODESSA, TX, 79762" },
    { name: "San Angelo Powersports", number: "(325) 947-2453", address: "4160 Houston Harte Expy, SAN ANGELO, TX, 76901" },
    { name: "Fastlane Cycles", number: "(936) 756-8881", address: "735 W. Davis St., CONROE, TX, 77301" },
    { name: "Freedom Powersports Dallas", number: "(214) 503-6262", address: "9938 PLANO ROAD, DALLAS, TX, 75238" },
    { name: "Freedom Powersports Decatur", number: "(940) 627-0627", address: "201 W. Thompson, DECATUR, TX, 76234" },
    { name: "Freedom Powersports Fort Worth", number: "(817) 244-5201", address: "3101 W Loop 820S, FORT WORTH, TX, 76116" },
    { name: "Freedom Powersports Hurst", number: "(817) 643-0460", address: "633 NE Loop 820, HURST, TX, 76053" },
    { name: "Freedom Powersports Johnson County", number: "(855) 438-9802", address: "1731 N Main Street, CLEBURNE, TX, 76033" },
    { name: "Freedom Powersports McKinny", number: "(972) 562-2077", address: "2110 N. CENTRAL EXPWY #100, MCKINNEY, TX, 75070" },
    { name: "Freedom Powersports Weatherford ", number: "(817) 596-3244", address: "1811 FT WORTH HWY, WEATHERFORD, TX, 76086" },
    { name: "Freedom Powersports Denton", number: "(940) 218-6323", address: "3801 N I35 Suite 110, DENTON, TX, 76207" },
    { name: "Freedom Powersports Farmers Branch", number: "(972) 418-6565", address: "13700 N. Stemmons Freeway, DALLAS, TX, 75284" },
    { name: "Freedom Powersports Lewisville", number: "(972) 420-4000", address: "13205 Stemmens Fwy, LEWISVILLE, TX, 75067" },
    { name: "F & T Valley Motorsports", number: "(956) 781-4141", address: "1007 E. EXP 83, PHARR, TX, 78577" },
    { name: "Gulf Coast BMW", number: "(713) 944-7951", address: "1210 College Avenue, SOUTH HOUSTON, TX, 77587" },
    { name: "GULF COAST TRIKES", number: "(832) 864-2085 ", address: "1907 LAWRENCE RD. #200, KEMAH, TX, 77565" },
    { name: "Mancuso Harley Davidson Central", number: "(713) 880-5666", address: "535 North Loop, HOUSTON, TX, 77018" },
    { name: "H & W Honda Yamaha", number: "(903) 938-9481", address: "3709 E End Blvd S, MARSHALL, TX, 75672" },
    { name: "H & W Marine", number: "(903) 927-1919", address: "3720 E. End Blvd., MARSHALL, TX, 75672" },
    { name: "H & W Honda of Palestine", number: "(903) 731-9590", address: "1534 E Palestine Ave, PALESTINE, TX, 75801" },
    { name: "H & W Powersports", number: "(903) 831-5500 ", address: "3601 New Basten Rd, TEXARKANA, TX, 75501" },
    { name: "Indian Motorcycle of Fort Hood", number: "(254) 690-1901", address: "305 W Central Texas Expy, HARKER HEIGHTS, TX, 76548" },
    { name: "Lone Star Yamaha", number: "(972) 445-0825", address: "520 W. Airport Hwy, PLANO, TX, 75026" },
    { name: "Lubbock Sports Inc", number: "(806) 793-2551", address: "4202 W. Loop 289, LUBBOCK, TX, 79407" },
    { name: "Lucky Penny Cycles", number: "(817) 283-1650", address: "1839 Airport Freeway, BEDFORD, TX, 76021" },
    { name: "Team Mancuso PowerSports 59", number: "(713) 772-7771", address: "10430 Southwest Freeway, HOUSTON, TX, 77074" },
    { name: "Team Mancuso PowerSports Gulf Freeway", number: "(281) 486-4604", address: "10222 Gulf Freeway Suite A, HOUSTON, TX, 77034" },
    { name: "Mancuso Harley Davidson Houston ", number: "(281) 970-9700", address: "12710 Crossroads Park, HOUSTON, TX, 77065" },
    { name: "Team Mancuso PowerSports North", number: "(281) 445-4227", address: "9650 North Freeway, HOUSTON, TX, 77037" },
    { name: "Team Mancuso PowerSports South", number: "(409) 938-3609", address: "4525 Gulf Freeway, LA MARQUE, TX, 77568" },
    { name: "Team Mancuso PowerSports Southwest", number: "(713) 995-9944", address: "7250 Southwest Freeway, HOUSTON, TX, 77074" },
    { name: "Mission City Indian Motorcycle", number: "(830) 981-2453", address: "28611 IH 10 W, BOERNE, TX, 78006" },
    { name: "MJB Sales Cycles", number: "(817) 834-1392", address: "8124 West Freeway, FORT WORTH, TX, 76108" },
    { name: "Moto Austin", number: "(512) 236-8822", address: "812 East Braker Lane, AUSTIN, TX, 78753" },
    { name: "MOTO EL PASO", number: "(915) 566-6666", address: "3750 GATEWAY EAST, EL PASO, TX, 79905" },
    { name: "Motor Trike", number: "(903) 842-3094", address: "22667 FM 15, TROUP, TX, 75789" },
    { name: "Ogden Powersports", number: "(936) 588-0411", address: "13095 Hwy 105 West STE 100, CONROE, TX, 77304" },
    { name: "Red River Motorsports", number: "(940) 665-5032", address: "900 N. Interstate 35, GAINESVILLE, TX, 76240" },
    { name: "Richardson Motorsports", number: "(972) 231-4832", address: "408 South Central Expway, RICHARDSON, TX, 75080" },
    { name: "Ride Now 6 Garland", number: "(469) 666-1691", address: "3914 S Shiloh Rd Suite 200, GARLAND, TX, 75041" },
    { name: "Harley Davidson Dallas", number: "(214) 495-0259", address: "304 Central Expressway South, ALLEN, TX, 75013" },
    { name: "Dallas Harley Davidson", number: "(972) 270-3962", address: "1334 W. Centerville Rd, GARLAND, TX, 75041" },
    { name: "Harley Davidson San Jacinto", number: "(281) 991-4275", address: "3636 E Sam Houston Pkwy. S, PASADENA, TX, 77505" },
    { name: "Sherman Powersports", number: "(903) 868-3030", address: "1205 S. Sam Rayburn Fwy., SHERMAN, TX, 75090" },
    { name: "Stadium Yamaha", number: "(972) 445-0825", address: "520 W. Airport, IRVING, TX, 75062" },
    { name: "Stubbs H-D", number: "(713) 644-7535", address: "4400 Telephone Rd, HOUSTON, TX, 77087" },
    { name: "Team XTreme", number: "(832) 940-0508", address: "5619 Southwest Fwy, HOUSTON, TX, 77057" },
    { name: "H & H East Tex Motorsports", number: "(281) 468-2499", address: "13667 HWY 190, SPRING, TX, 77380" },
    { name: "Velocity Powersports", number: "(361) 570-6767", address: "13102 N. Navarro, VICTORIA, TX, 77904" },
    { name: "Victoria Harley Davidson", number: "(361) 575-7881", address: "608 N Moody St, VICTORIA, TX, 77901" },
    { name: "BMW Motorcycles of the Woodlands", number: " (936) 271-7600", address: "198 Ed English Drive, CONROE, TX, 77385" },
    { name: "Woods Fun Center", number: "(512) 459-3311", address: "11405 N. IH 35, AUSTIN, TX, 78753" },
]
const Arizona = [
    { name: "American Bike and Trike", number: "(480) 218-5822", address: "3272 S. 147th P1, GILBERT, AZ, 85297" },
    { name: "Arizona Kawasaki Victory", number: "(480) 982-3363", address: "1015 W Apache Trail, APACHE JUNCTION, AZ, 85120" },
    { name: "Arizona Kawasaki Triumph", number: "(520) 333-2201", address: " 817 S Country Club Dr., MESA, AZ, 85210" },
    { name: "DESERT WIND HARLEY DAVIDSON", number: "(480) 894-0404", address: "922 S COUNTRY CLUB DR, MESA, AZ, 85210" },
    { name: "EagleRider Arizona", number: "(480) 905-9397", address: "1000 N. McClintock, QUEEN CREEK, AZ, 85142" },
    { name: "GOAZ Motorcycles Scottsdale", number: "(480) 609-1800", address: "15500 N. HAYDEN ROAD, SCOTTSDALE, AZ, 85260" },
    { name: "GO AZ Motorcycles West ", number: "(623) 322-6700", address: "16844 N Arrowhead Fountain, PEORIA, AZ, 85382" },
    { name: "Harley Davidson of Scottsdale", number: "(480) 905-1903", address: "15600 N. Hayden Rd, SCOTTSDALE, AZ, 85260" },
    { name: "Kelly's Kawasaki", number: "(480) 969-9610", address: "817 S Country Club Dr, MESA, AZ, 85210" },
    { name: "Moto City", number: "(623) 932-0600", address: "310 N Dysart Rd, AVONDALE, AZ, 85323" },
    { name: "Phoenix MV Agusta", number: "(480) 284-8433", address: "430 E. Warner Rd., CHANDLER, AZ, 85225" },
    { name: "Old Pueblo Harley Davidson", number: "(520) 579-6767", address: "7503 E 22nd St, TUCSON, AZ, 85710" },
    { name: "On Any Moto", number: "(520) 269-6606", address: "1655 W. Grant Rd., TUCSON, AZ, 85745" },
    { name: "Outdoor Sports", number: "(928) 772-0575", address: "9100 E. Valley Rd, PRESCOTT VALLEY, AZ, 86314" },
    { name: "River Rat MotorSports Bullhead", number: "(928) 220-8215", address: "1030 Hwy. 95, BULLHEAD CITY, AZ, 86429" },
    { name: "Arrowhead Harley Davidson", number: "(623) 247-5542", address: "16130 N Arrowhead Fountain Ctr Dr, PEORIA, AZ, 85382" },
    { name: "Indian Motorcycle of Tucson", number: "(520) 290-7390", address: "4037 N Oracle Rd, TUCSON, AZ, 85705" },
    { name: "Ride Now Peoria", number: "(623) 334-3434", address: "8546 W. LUDLOW DRIVE, STE 100, PEORIA, AZ, 85381" },
    { name: "Ride Now Phoenix", number: "(602) 992-8620", address: "17202 N Cave Creek Rd, PHOENIX, AZ, 85032" },
    { name: "Ride Now Powersports Tucson", number: "(520) 747-9141", address: "7501 E. 22ND STREET, TUCSON, AZ, 85710" },
    { name: "Tucson Harley-Davidson", number: "(520) 751-3380", address: "7355 N I-10 Eastbound Frontage Rd, TUCSON, AZ, 85743" },
    { name: "RideNow on Apache Junction", number: "(480) 986-6922", address: "11357 E. APACHE TRAIL, TEMPE, AZ, 85283" },
    { name: "RideNow Chandler", number: "(480) 503-3333", address: "3333 N. ARIZONA AVENUE, CHANDLER, AZ, 85225" },
    { name: "East Chandler Harley Davidson", number: "(480) 496-6800", address: "6895 W Chandler Blvd, CHANDLER, AZ, 85226" },
    { name: "RideNow Euro", number: "(480) 898-0559", address: "2677 East Willis Rd., CHANDLER, AZ, 85286" },
    { name: "RideNow Goodyear", number: "(623) 214-6400", address: "13690 W. TEST DRIVE, GOODYEAR, AZ, 85338" },
    { name: "Ride Now on Ina", number: "(520) 579-3939", address: "4375 W. INA ROAD, TUCSON, AZ, 85741" },
    { name: "RideNow Surprise", number: "(623) 474-3740", address: "15380 W. BELL ROAD, SURPRISE, AZ, 85374" },
    { name: "Sierra Vista Harley Davidson", number: "(520) 458-9500", address: "176 W Fry Blvd, SIERRA VISTA, AZ, 85635" },
    { name: "Ridenow Victory BMW", number: "(480) 899-9123", address: "1701 N. ARIZONA AVENUE, CHANDLER, AZ, 85225" },
    { name: "Mother Road Harley Davidson", number: "(928) 757-1166", address: "2501 Beverly Avenue, KINGMAN, AZ, 86409" },
    { name: "River Rat Motorsports", number: "(928) 302-3001", address: "1680 W. Acoma Blvd, LAKE HAVASU CITY, AZ, 86403" },
    { name: "River Rat Motorsports Kingman", number: "(928) 757-2480", address: "4195 Stockton Hill Rd., KINGMAN, AZ, 86409" },
    { name: "Scottsdale Cycle Center", number: "(480) 621-6217", address: "14885 N 83RD PL  #106, SCOTTSDALE, AZ, 85260" },
    { name: "Buddy Stubbs Harley Davidson", number: "(623) 465-1122", address: "41715 N. 41ST Drive, PHOENIX, AZ, 85086" },
    { name: "Buddy Stubbs Harley Davidson", number: "(602) 971-3400", address: "13850 N. Cave Creek Road, PHOENIX, AZ, 85022" },
    { name: "Superstition Harley Davidson", number: "(480) 346-0600", address: "2910 W Apache Trail, APACHE JUNCTION, AZ, 85120" },
    { name: "Arizona Kawasaki KTM Triumph", number: "(520) 333-2201", address: "3741 N I-10 Frontage Rd Ste 101, TUCSON, AZ, 85705" },
    { name: "Victory of Mesa", number: "(480) 668-7969", address: "833 S Country Club Dr, MESA, AZ, 85210" },
]
const Nevada = [
    { name: "All Power Sports", number: "(702) 798-1024", address: "6545 W Sahara Ave, LAS VEGAS, NV, 89146" },
    { name: "Battle Born Harley Davidson", number: "(775) 882-7433", address: "2900 Research Way, CARSON CITY, NV, 89706" },
    { name: "Below Book Vehicles", number: "(702) 891-5099", address: "2901 S Highland Dr., LAS VEGAS, NV, 89109" },
    { name: "Carter Powersports", number: "(702) 795-2000", address: "6275 S. Decatur, LAS VEGAS, NV, 89118" },
    { name: "RideNow DUCATI LAS VEGAS", number: "(702) 998-2993", address: "6550 S. BUFFALO DR. STE 170, LAS VEGAS, NV, 89113" },
    { name: "Freedom Euro Cycle", number: "(702) 430-3500", address: "5031 Wagon Trail Ave. #105, LAS VEGAS, NV, 89118" },
    { name: "RideNow on Boulder", number: "(702) 451-1121", address: "6350 Boulder Hwy, LAS VEGAS, NV, 89122" },
    { name: "RideNow on Craig", number: "(702) 214-5380", address: "4120 E Craig Rd, NORTH LAS VEGAS, NV, 89030" },
    { name: "RideNow Indian Las Vegas", number: "(702) 440-6377", address: "4020 Boulder Hwy, LAS VEGAS, NV, 89121" },
    { name: "RideNow Powersports On Rancho", number: "(702) 656-1955", address: "3800 N. RANCHO DRIVE, LAS VEGAS, NV, 89130" },
    { name: "RideNow Sin City Indian Motorcycle", number: "(702) 685-9222", address: "6550 S. BUFFALO DR. SUITE 170, LAS VEGAS, NV, 89113" },
]
const California = [
    {
        name: "Back Alley Motorcycles",
        number: "(951) 587-0900",
        address: "28900 Old Town Front Rd, TEMECULA, CA, 92590"
    },
    {
        name: "Beverly Hills Ducati",
        number: "(310) 360-0916",
        address: "428 N. La Cienega Blvd., LOS ANGELES, CA, 90048"
    },
    {
        name: "Biggs Harley Davidson",
        number: "(760) 481-7300",
        address: "1040 Los Vallecitos Blvd. #113, SAN MARCOS, CA, 92069"
    },
    {
        name: "Coronado Beach Harley Davidson",
        number: "(619) 477-4477",
        address: "3201 HOOVER AVE, NATIONAL CITY, CA, 91950"
    },
    {
        name: "Chico Honda Motorsports",
        number: "(530) 342-4216",
        address: "11096 Midway, CHICO, CA, 95928"
    },
    {
        name: "Coyne Powersports Banning",
        number: "(951) 845-4882",
        address: "2301 W. Ramsey St., BANNING, CA, 92220"
    },
    {
        name: "Del Amo Long Beach ",
        number: "(562) 427-2779",
        address: "3291 Cherry Ave, LONG BEACH, CA, 90807"
    },
    {
        name: "Del Amo Motorcycles",
        number: "(949) 642-4343",
        address: "2401 Pullman St., SANTA ANA, CA, 92705"
    },
    {
        name: "Del Amo Motorcycles",
        number: "(310) 220-2223",
        address: "2500 Marine Ave, REDONDO BEACH, CA, 90278"
    },
    {
        name: "Del Amo Motorsports",
        number: "(619) 420-2300",
        address: "1890 Auto Park Pl, CHULA VISTA, CA, 91911"
    },
    {
        name: "Douglas Motorcycles",
        number: "(909) 884-4776",
        address: "390 N. H St., SAN BERNARDINO, CA, 92410"
    },
    {
        name: "DUCATI NEWPORT BEACH",
        number: "(949) 706-1616",
        address: "1601 NEWPOER RD., COSTA MESA, CA, 92627"
    },
    {
        name: "East Bay Motorsports",
        number: "(510) 889-7900",
        address: "21756 Foothill Blvd, HAYWARD, CA, 94541"
    },
    {
        name: "Freedom Rides",
        number: "(916) 434-2980",
        address: "643 Lincoln Blvd, LINCOLN, CA, 95648"
    },
    {
        name: "Temecula Harley Davidson",
        number: "(951) 506-6903",
        address: "28964 Old Town Front Street, TEMECULA, CA, 92590"
    },
    {
        name: "High Adrenaline",
        number: "(925) 687-7742",
        address: "1150 Concord Ave., CONCORD, CA, 94520"
    },
    {
        name: "Honda Yamaha Sports Center",
        number: "(530) 673-5676",
        address: "2530 Colusa Hwy, YUBA CITY, CA, 95993"
    },
    {
        name: "INDIAN OF ORANGE COUNTY",
        number: "(714) 908-4551",
        address: "13031 GOLDENWEST ST, WESTMINSTER, CA, 92683"
    },
    {
        name: "San Diego Indian Motorcycles",
        number: "(619) 446-0022",
        address: "2400 Kettner Blvd Suite 101, SAN DIEGO, CA, 92101"
    },
    {
        name: "Indian Motorcycles San Jose",
        number: "(408) 571-6000",
        address: "460 Meridian Ave, SAN JOSE, CA, 95126"
    },
    {
        name: "Indian Victory Los Angeles",
        number: "(310) 536-6777",
        address: "11860 La Cienega Blvd, HAWTHORNE, CA, 90250"
    },
    {
        name: "LA Cycle Sports",
        number: "(310) 677-5800",
        address: "8515 S. LA Cienega, INGLEWOOD, CA, 90301"
    },
    {
        name: "Long Beach BMW Motorcycles",
        number: "(562) 426-1200",
        address: "2125 E. Spring St., LONG BEACH, CA, 90806"
    },
    {
        name: "Mid-Cities MotorSports",
        number: "(562) 531-1225",
        address: "15725 Lakewood Blvd., PARAMOUNT, CA, 90723"
    },
    {
        name: "MOTO FORZA",
        number: "(760) 746-6001",
        address: "1220 West Washington Ave., ESCONDIDO, CA, 92029"
    },
    {
        name: "The Motor Cafe",
        number: "(408) 245-7840",
        address: "1289 W. El Camino Real, SUNNYVALE, CA, 94087"
    },
    {
        name: "No. County's House of Motorcycles",
        number: "(760) 433-4333",
        address: "1725 Hacienda Dr. Ste. C, VISTA, CA, 92081"
    },
    {
        name: "NORTH COUNTY YAMAHA",
        number: "(760) 432-9501",
        address: "2333 MONTIEL RD, SAN MARCOS, CA, 92069"
    },
    {
        name: "North County Indian Motorcycle",
        number: "(760) 798-4030",
        address: "1040 Los Vallecitos Blvd. #102, SAN MARCOS, CA, 92069"
    },
    {
        name: "Orange County Harley Davidson",
        number: "(949) 727-4464",
        address: "8677 Research Dr, IRVINE, CA, 92618"
    },
    {
        name: "Pacific Motorsports",
        number: "(310) 891-6990",
        address: "1358 Pacific Coast Highway, HARBOR CITY, CA, 90710"
    },
    {
        name: "Riverside Harley Davidson",
        number: "(951) 785-0100",
        address: "7688 INDIANA AVE, RIVERSIDE, CA, 92504"
    },
    {
        name: "EL Cajon Harley Davidson",
        number: "(619) 444-1123",
        address: "621 El Cajon Blvd, EL CAJON, CA, 92020"
    },
    {
        name: "Robinson Motorsports",
        number: "(858) 705-1238",
        address: "9113 Olive Drive, SPRING VALLEY, CA, 91977"
    },
    {
        name: "Rocket MotorCycles",
        number: "(619) 276-2177",
        address: "5171 Morena PL., SAN DIEGO, CA, 92110"
    },
    {
        name: "ROCKRIDGE TWO WHEELS",
        number: "(510) 594-0789",
        address: "5291 College Ave, OAKLAND, CA, 94618"
    },
    {
        name: "San Diego Harley Davidson",
        number: "(858) 616-6999",
        address: "4645 Morena Blvd, SAN DIEGO, CA, 92117"
    },
    {
        name: "Santa Rosa Powersports",
        number: "(707) 545-1672",
        address: "910 Santa Rosa Ave, SANTA ROSA, CA, 95404"
    },
    {
        name: "SO CAL AUTO SALES",
        number: "(818) 558-7401",
        address: "1411 W Burbank Blvd, BURBANK, CA, 91506"
    },
    {
        name: "So Cal Trike",
        number: "(760) 744-1688",
        address: "1050 Linda Vista Drive, SAN MARCOS, CA, 92078"
    },
    {
        name: "So Cal Triumph",
        number: "(714) 256-6700",
        address: "515 W Lambert, BREA, CA, 92821"
    },
    {
        name: "Temple City Powersports",
        number: "(626) 287-6167",
        address: "5300 N Rosemead Blvd, SAN GABRIEL, CA, 91776"
    },
    {
        name: "Tracy Motorsports Inc",
        number: "(209) 832-3400",
        address: "3255 Auto Plaza Way, TRACY, CA, 95304"
    },
    {
        name: "Wilson's Motorcycles",
        number: "(559) 237-0215",
        address: "284 W. Shaw Avenue, CLOVIS, CA, 93612"
    }
];

class DealersByState extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [
                Alabama,
                Florida,
                SouthCarolina,
                NorthCarolina,
                Georgia,
                Texas,
                Arizona,
                California,
                Nevada
            ]
        };
    }

    render(props) {
        console.log(this.props.match)
        let stateID = this.props.match.params.stateID;
        return (
            <div className="ds-page">
                <div className="ds-content">
                    <Link
                        className="back-btn lg"
                        to={{ pathname: "/dealers", state: { scrollRef: "map" } }}


                    >
                        <FaAngleLeft /> Back
                </Link>
                    <h1 className="ds-state-name">{stateID}</h1>
                    <h2 className="ds-header">Dealers</h2>

                    <div className="ds-list">
                        {this.state.partners[this.props.match.params.index].map((dealer, index) => {
                            return (<div className="ds-item">
                                <p className="ds-name">
                                    {dealer.name}
                                </p>
                                <p className="ds-number">
                                    {dealer.number}
                                </p>
                                <p className="ds-address">
                                    {dealer.address}
                                </p>
                            </div>)
                        })}
                    </div>
                </div>
            </div >
        );
    }
}

export default DealersByState;
