import React from "react";
import "../App.css";
import {
    FaInstagram,
    FaTwitter,
    FaLinkedinIn,
    FaFacebookF
} from "react-icons/fa";
import { Link } from "react-router-dom";

import logo from "../images/Thunder_Road.png";

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        
    }

    handleClick = page => {
        this.props.updateNavBar(page);
      };

    render() {

        var logoBGStyle = {
            backgroundImage: "url(" + logo + ")",
            backgroundSize: "cover",
            backgroundPosition: "center"
        };

        return (
            <div className="footer-wrap">
                <div className="footer-row">
                    <div className="footer-content">
                        <div className="footer-section">
                            <p className="footer-links-header"> SITE LINKS </p>
                            <div className="links">
                                <Link className="footer-link" to="/"
                                onClick={() => {
                                    this.handleClick("/");
                                  }}>
                                    Home
                                </Link>
                                <Link className="footer-link" to="/customers"
                                onClick={() => {
                                    this.handleClick("/customers");
                                  }}>
                                    Customers
                                </Link>
                                <Link className="footer-link" to="/dealers"
                                onClick={() => {
                                    this.handleClick("/dealers");
                                  }}>
                                    Dealers
                                </Link>
                                <Link className="footer-link" to="/news"
                                onClick={() => {
                                    this.handleClick("/news");
                                  }}>
                                    News
                                </Link>
                                <a
                                    className="footer-link"
                                    target="_blank"
                                    href="https://decisionlender.solutions/tci/#/auth/dealerLogin/default/dealer/default"
                                >
                                    Dealer Login
                                </a>
                            </div>
                        </div>
                        <div className="footer-section social">
                            <p className="footer-links-header"> SOCIAL MEDIA </p>
                            <div className="links social">
                                <a href="https://www.linkedin.com/in/donal-hummer-419b6995/" target="_blank" className="link-icon">
                                    <FaLinkedinIn />
                                </a>
                                <a href="https://www.facebook.com/ThunderRoadFinancial/" target="_blank" className="link-icon">
                                    <FaFacebookF />
                                </a>

                            </div>

                            <p className="footer-links-header"> CONTACT US</p>
                            <div className="links">
                                <p className="footer-contact">Phone: (775) 332-1965</p>
                                <a
                                    className="footer-contact-email"
                                    target="_top"
                                    href="mailto:info@thunderroadfinancial.com"
                                >
                                    Email: info@thunderroadfinancial.com
                                </a>
                                <p className="footer-contact">Post: P.O. Box 19849, Reno, Nevada 89521</p>
                            </div>
                        </div>


                    </div>

                    <div className="footer-logo" style={logoBGStyle} />
                </div>
                <div className="footer-company-wrap">
                    <p className="footer-company">
                        ThunderRoad Financial LLC.  All rights reserved.
                    </p>
                </div>
            </div>
        );
    }
}