import React from "react";

export default class CustAcct extends React.Component { 

  render() {
    return (
      <div className={this.props.acctSelect !== "none" ? "acct" : "accth"}>
        <div className={this.props.acctStyle}>
          <div className="pay-methods">
            <div className="acct-sect-header subhead"> PAYMENT METHODS</div>
            <div className="acct-sect-text body">
              You may pay by calling customer service, by mail, or by clicking
              the payment portal button
            </div>
          </div>
          <div className="submit-wrap-btn">
            <a
              className={
                this.props.acctSelect === "507 acct"
                  ? "submit-button g input-text sublg"
                  : "submit-button b input-text sublg"
              }
              target="_blank"
              without="true" 
              rel="noopener noreferrer"
              href={this.props.payPortal}
            >
              PAYMENT PORTAL
            </a>
          </div>
          <div className="acct-pc">
            <div className={"acct-box sect" + " " + this.props.acctStyle}>
              <div className="acct-section">
                <div className="acct-sect-subheader">Customer Service</div>
                <div className="acct-sect-text">{this.props.phoneNum}</div>
                <div className="acct-sect-text">
                  <a
                    className="pay-link"
                    target="_top"
                    href={`mailto:${this.props.email}`}
                  >
                    Customer Service Email
                  </a>
                </div>
                {this.props.acctSelect === "507 acct" ? (
                  <div className="acct-sect-text">
                    {/* <a className="pay-link">Terms and Conditions</a> */}
                  </div>
                ) : (
                  <div />
                )}
              </div>
              <div className="acct-section">
                <div className="acct-sect-subheader">Hours</div>
                <div className="acct-sect-text">{this.props.acctHours}</div>
                <div className="acct-sect-text">{this.props.acctHours2}</div>
                <div className="acct-sect-text">{this.props.acctHours3}</div>
              </div>
            </div>
            <div className={"acct-box sect" + " " + this.props.acctStyle}>
              <div className="acct-section ">
                <div className="acct-sect-subheader ">
                  Regular Mail Payments
                </div>
                <div className="acct-sect-text">{this.props.regMail1}</div>
                <div className="acct-sect-text">{this.props.regMail2}</div>
              </div>
              <div className="acct-section ">
                <div className="acct-sect-subheader ">
                  Overnight Mail Payments
                </div>
                <div className="acct-sect-text"> {this.props.mail}</div>
                <div className="acct-sect-text"> {this.props.mail2}</div>
                <div className="acct-sect-text"> {this.props.mail3}</div>
                <div className="acct-sect-text"> {this.props.mail4}</div>
              </div>
            </div>
          </div>
          <div className="acct-box a">
            <div className="acct-box sect">
              If you have any questions about your account number, please call
              (775) 332-1965
            </div>
          </div>
        </div>
      </div>
    );
  }
}
