import React from "react";
import axios from 'axios';
import "../App.css";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailInput: "",
      nameInput: "",
      highlightEmail: false,
      highlightName: false,
      highlightSubject: false,
      displayReminder: false,
      subject: "",
      message: "",
      reminderText: "All highlighted fields are required."
    };
  }

  submitForm = async () => {
    var emailField = document.getElementById("emailField");
    if (
      emailField.checkValidity() &&
      this.state.nameInput !== "" &&
      this.state.subject !== ""
    ) 
    {
      var postBody = {
        email: this.state.emailInput,
        name: this.state.nameInput,
        subject: this.state.subject,
        message: this.state.message
      };

      await axios.post('https://f66xw3ag8b.execute-api.us-east-2.amazonaws.com/prod', postBody);
      this.setState({
        emailInput: "",
        nameInput: "",
        message: "",
        subject: "",
        highlightEmail: false,
        highlightName: false,
        highlightSubject: false,
        displayReminder: true,
        reminderText: "The form has been submitted thank you!"
      });
    }
    else {
      if (this.state.nameInput === "") {
        this.setState({
          highlightName: true,
          displayReminder: true,
          reminderText: "All highlighted fields are required!"
        });
      }
      if (!emailField.checkValidity()) {
        this.setState({
          highlightEmail: true,
          displayReminder: true,
          reminderText: "All highlighted fields are required! Make sure your email is valid."
        });
      }
      if (this.state.subject === "") {
        this.setState({
          highlightSubject: true,
          displayReminder: true,
          reminderText: "All highlighted fields are required!"
        });
      }
    }
  };

  handleEmailChange = e => {
    e.preventDefault();
    if (document.getElementById("emailField").checkValidity()) {
      this.setState({ highlightEmail: false });
    }
    this.setState({ emailInput: e.target.value });
  };

  handleNameChange = e => {
    e.preventDefault();
    this.setState({ nameInput: e.target.value, highlightName: false });
  };

  handleMessageChange = e => {
    e.preventDefault();
    this.setState({ message: e.target.value });
  };

  handleSubjectChange = e => {
    e.preventDefault();
    this.setState({ subject: e.target.value, highlightSubject: false });
  };

  render() {
    return (

      <div className="contact-form">
        <h4 className="contact-note subhead">
          INTERESTED IN BECOMING A DEALER? SUBMIT A FORM HERE TO GET STARTED
          </h4>
        <p
          className={
            this.state.displayReminder ? " reminder " : " reminder-hidden "
          }
        >
          {this.state.reminderText}
        </p>
        <div className="form-inputs-wrap">
          <input
            id="name"
            type="text"
            onChange={this.handleNameChange}
            value={this.state.nameInput}
            className={
              this.state.highlightName
                ? " input-text form highlighted"
                : " input-text form "
            }
            placeholder=" Name "
            required
          />
          <input
            id="emailField"
            type="email"
            onChange={this.handleEmailChange}
            value={this.state.emailInput}
            className={
              this.state.highlightEmail
                ? "input-text form highlighted"
                : "input-text form "
            }
            placeholder=" Email "
            required
          />
          <input
            id="subject"
            type="text"
            onChange={this.handleSubjectChange}
            value={this.state.subject}
            className={
              this.state.highlightSubject
                ? "input-text form highlighted"
                : "input-text form "
            }
            placeholder=" Subject "
            required
          />
          <textarea
            onChange={this.handleMessageChange}
            value={this.state.message}
            className="form-text-area input-text"
            placeholder=" Message"
          ></textarea>

          <div className="submit-button input-text" onClick={() => {
            this.submitForm();
          }}>
            Submit
            </div>
        </div>
      </div>

    );
  }
}
export default ContactForm;
