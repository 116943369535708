import React from "react";
import ReactGA from 'react-ga';

export default class NewsArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props.articleInfo);
  }

  render() {
    return (
      <div>
        {this.props.articleInfo.map((article, index) => {
          console.log(article.link);
          return (
            <div className="news-article" key={index}>
              <div className="article-wrap" 
              onClick={() => {
                ReactGA.event({
                    category: 'button',
                    action: 'Went to news article'
                })
            }}>
                <img src={article.image} alt="article-image" className="article-image" alt={article.link} />
                <img src={article.image2} alt="article-image" className="article-image2" alt={article.link}/>
                <div className="rm-wrap">
                  <a href={article.link} target="_blank" without="true" rel="noopener noreferrer" className="rm-btn smll">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
