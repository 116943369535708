import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IoIosMenu as Burga } from "react-icons/io";
import logo from "../images/Thunder_Road.png";

export default class HamburgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClosed: false
    };
  }
  toggleNav = () => {
    this.setState({ isClosed: !this.state.isClosed });
  };

  handleClick = () => {
    window.scrollTo({ top: 0, left: 0 });
    this.setState({ isClosed: false });
  };

  render() {
    return (
      <div className="nav-mobile">
        <div className="nav-header">
          <div className="nav-burger-icon" onClick={this.toggleNav}>
            <Burga size={36} />
          </div>
          <div className="logo-wrap">
            <img src={logo} alt= "tr-logo" className="nav-logo"></img>
          </div>
          <div className="dl-wrap"></div>
        </div>

        <div className={this.state.isClosed ? "nav-menu visible" : "nav-menu hidden"}>
          <Link
            className={this.state.isClosed ? "burger-item" : "burger-item hidden"}
            to="/"
            onClick={() => {
              this.handleClick();
            }}
          >
            HOME
          </Link>
          <Link
           className={this.state.isClosed ? "burger-item" : "burger-item hidden"}
            to="/customers"
            onClick={() => {
              this.handleClick();
            }}
          >
            CUSTOMERS
          </Link>
          <Link
            className={this.state.isClosed ? "burger-item" : "burger-item hidden"}
            to="/dealers"
            onClick={() => {
              this.handleClick();
            }}
          >
            DEALERS
          </Link>
          <Link
           className={this.state.isClosed ? "burger-item" : "burger-item hidden"}
            to="/news"
            onClick={() => {
              this.handleClick();
            }}
          >
            NEWS
          </Link>
          <a
            className={this.state.isClosed ? "burger-item" : "burger-item hidden"}
            target="_blank"
            href="https://decisionlender.solutions/tci/#/auth/dealerLogin/default/dealer/default"
          >
            DEALER LOGIN
          </a>
        </div>
      </div>
    );
  }
}
