import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/Thunder_Road.png";

import HamburgerMenu from "./HamburgerMenu";

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewing: window.location.pathname
    };
  }

  handleClick = page => {
    this.props.updateNavBar(page);
    this.setState({ viewing: page });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    console.log(window.location.pathname);
    this.setState({ viewing: nextProps.currentPage });
  }

  render() {
    console.log(window.location.pathname);
    return (
      <div className="nav-bar">
        <div className="nav-wrap">
          <div className="nav-left">
            <img className="nav-logo"  alt="tr-logo" src={logo} />

            <Link
              className={
                this.state.viewing === "/" ? "nav-item viewing" : "nav-item"
              }
              to="/"
              onClick={() => {
                this.handleClick("/");
              }}
            >
              HOME
            </Link>

            <Link
              className={
                this.state.viewing === "/customers"
                  ? "nav-item viewing"
                  : "nav-item"
              }
              to="/customers"
              onClick={() => {
                this.handleClick("/customers");
              }}
            >
              CUSTOMERS
            </Link>

            <Link
              className={
                this.state.viewing === "/dealers"
                  ? "nav-item viewing"
                  : "nav-item"
              }
              to="/dealers"
              onClick={() => {
                this.handleClick("/dealers");
              }}
            >
              DEALERS
            </Link>

            <Link
              className={
                this.state.viewing === "/news" ? "nav-item viewing" : "nav-item"
              }
              to="/news"
              onClick={() => {
                this.handleClick("/news");
              }}
            >
              NEWS
            </Link>
          </div>

          <div className="nav-right">
            <a
              className="dealer-login-button-text"
              target="_blank"
              href="https://decisionlender.solutions/tci/#/auth/dealerLogin/default/dealer/default"
            >
              DEALER LOGIN
            </a>
          </div>
        </div>
        <HamburgerMenu className="nav-mobile" />
      </div>
    );
  }
}
